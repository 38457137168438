import React, {useState, useEffect} from "react"
import Layout from "../components/layout"
import styled from "styled-components"

const FirstSection = styled.div`
  display: flex;
  justify-content: center;
  margin: 5%;

  h1 {
    font-family: 'A Heavy';
  }
`


const NotFoundPage = () => {
  const [hasMounted, setHasMounted] = useState(false) 

    useEffect(() => {
        setHasMounted(true)
    }, [])

  return hasMounted ? (        
    <Layout>
      <FirstSection>
        <h1>Esta página no existe</h1>
      </FirstSection>
    </Layout>
    ) : (
    null
    )
}

export default NotFoundPage
